@import './../../../../variables';

.dropdown_menu {
  position: absolute;
  background-color: #fff;
  font-size: 1.4rem;
  box-shadow: 0 4px 16px rgba(128, 128, 128, 0.25);
  border-radius: 2px;
  padding: 0.8rem 0;
  right: 2%;
  list-style: none;
  min-width: 18rem;
  pointer-events: auto;
  overflow-y: scroll;
  max-height: 70%;

  &_header {
    padding: 0.5rem 1.2rem;
    line-height: 1.43;
    text-align: left;
  }

  &_category {
    list-style: none;
  }

  &_list {
    display: flex;
    padding: 0.5rem 1.2rem;
    line-height: 1.43;
    justify-content: space-between;

    &:hover {
      background-color: #f2f3f5;
      cursor: pointer;
    }
  }

  &_divider {
    height: 1px;
    margin: 0.5rem 0;
    overflow: hidden;
    background-color: rgba(128, 128, 128, 0.4);
  }

  &_icon {
    stroke: $color-red;
    stroke-width: 3;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
