@import "./../../../variables";

.add_place_window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: $color-white-1;
  border-radius: 9px;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
  z-index: 3000;
  transition: all 0.5s;

  @media only screen and (max-width: $bp-smallest) {
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  @media only screen and (min-width: $bp-small) {
    width: 40%;
    padding: 2rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: 2000;
  transition: all 0.5s;
}

.btn__close_modal {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 1.6rem;
  font-size: 3.5rem;
  cursor: pointer;
  border: none;
  background: none;
}

.upload {
  &__heading {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  & label,
  span {
    font-size: 1.2rem;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.required {
  color: $color-red;
}
