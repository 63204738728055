@import "./../../../variables";

.HeaderLogo {
  &:link,
  &:visited {
    color: $color-white;
    font-size: 1.6rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s;
    font-weight: 400;
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;

    // 500px
    @media only screen and (max-width: $bp-smallest) {
      font-size: 1rem;
    }
  }

  &:hover,
  :active {
    opacity: 0.7;
  }

  &:not(:last-child) {
    margin-right: 3rem;
  }

  &_el {
    height: 9rem;
  }
}
