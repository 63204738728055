@import './../../../variables';

.hotel {
  &__card {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
    background-color: $color-white-1;
    transition: 0.3s all;
    display: flex;
    flex-direction: column;
    width: 20rem;
  }

  &__card_container {
    grid-row: 3/4;
    grid-column: 1/3;
  }

  &__card_wrapper {
    padding: 2rem 0;
    display: grid;
    grid-column-gap: 3rem;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    max-width: 100%;
  }

  &__card__picture {
    position: relative;
    height: 15rem;
  }

  &__card_details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.4rem;
    grid-column-gap: 2rem;
    padding: 0.5rem 1.5rem;
  }

  &__card_data {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
  }

  &__review {
    margin-right: 0.5rem;
  }
}

.linkbox {
  position: relative;

  & a {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    &:hover {
      opacity: 0.2;
      background-color: $color-grey;
    }
  }
}

.card {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  background-color: $color-white-1;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
  }

  &__picture {
    position: relative;
    height: 30rem;

    &_img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      background-color: $color-grey-2;
    }

    &_top_image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.25rem;
    grid-column-gap: 2rem;
    padding: 2.5rem 3rem;
  }

  &__sub_heading {
    font-size: 1.2rem;
    font-weight: 700;
    grid-column: 1 / -1;
  }

  &__data {
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    &_grid {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, 6.5rem);
    }
  }
}

.icon_wrapper {
  text-align: right;
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.mr_10 {
  margin-right: 1rem;
}

.btn_genre {
  font-size: 0.5rem;
  color: $color-grey;
  background-color: $color-white-1;
  border: 0.1rem solid $color-blue;
  border-radius: 100vh;
  padding: 0.5rem;
  text-decoration: none;
  text-align: center;
}

.p_05 {
  padding: 0.5rem;
}

.ml_05 {
  margin-left: 0.5rem;
}

.icon_style {
  font-size: 2rem;
  color: $color-grey-2;
}

.edit_icon,
.trash_icon {
  cursor: pointer;
  pointer-events: auto;
  color: $color-white-1;
  font-size: 3rem !important;

  &:hover {
    opacity: 0.8;
  }
}
