@import "./../../variables";

.main {
  background-color: $color-white;
  padding: 8rem 6rem;

  @media only screen and (max-width: $bp-smallest) {
    padding: 8rem 0.5rem;
  }
}

.contact__title {
  margin-bottom: 2rem;
  font-size: 3rem;
}

.contact__description {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.contact_form {
  margin: 0 auto;
  max-width: 55rem;
  background-color: $color-white-1;
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  padding: 5rem 7rem;
  border-radius: 5px;
}
