@import "./../../variables";

.section_about {
  height: 90vh;
  position: relative;

  &__heading {
    margin-top: 17%;
    position: absolute;
    left: 25%;
    font-family: "Amaranth", sans-serif;

    &_text {
      font-size: 6.5rem;
      font-weight: 700;
      display: inline-block;
      color: grey;
      letter-spacing: 0.2rem;
    }

    &_detail {
      font-size: 1.5rem;
      color: grey;
    }
  }
}

.card {
  &__picture {
    position: relative;
    height: 30rem;

    &_img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      background-color: $color-grey-1;
    }
  }
}

.bg_video {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  overflow: hidden;
}

.bg_video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section_feature {
  margin: 0 auto;
  width: 80%;
  padding: 10rem 0;
}

.feature__heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

.flex_wrapper {
  display: flex;

  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
}

.flex_box {
  width: 30%;

  @media only screen and (max-width: $bp-small) {
    width: 100%;
  }
}

.mr_5 {
  margin-right: 5%;
}

.detail_box {
  margin: 2rem;

  &__text {
    font-size: 1.2rem;
  }
}

.br_ra {
  border-radius: 7rem;
}
