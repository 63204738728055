@import "./../../variables";

.subject_container {
  background-color: $color-white;
}

.title_text {
  margin-top: 3rem;
  margin-left: 3rem;
}

.place_container {
  background-color: $color-white;
  padding: 3rem 3rem 0;
  grid-row: 2/3;
  grid-column: 2/3;
  display: grid;
  grid-template-rows: repeat(2, 27rem);
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: $bp-small) {
    display: initial;
  }
}

.place {
  position: relative;
  margin-bottom: 1.5rem;

  &__map {
    @media only screen and (max-width: $bp-small) {
      height: 30rem;
      width: 95%;
      margin: 0 auto;
    }
  }

  &__img {
    @media only screen and (max-width: $bp-small) {
      text-align: center;
    }
  }
}

.address_text {
  display: inline;
  margin-right: 1.5rem;
}

.memo_text {
  margin: 1.5rem 0;
}

.memo_wrapper {
  display: block;
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  width: 95%;
  background-color: $color-grey-1;
  padding: 1.25rem 1.75rem;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 4px;
  height: 75%;
  overflow-wrap: break-word;
  overflow: scroll;
}

.map {
  width: 100%;
  height: 100%;
}

.hotel {
  &__card {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
    background-color: $color-white-1;
    transition: 0.3s all;
    display: flex;
    flex-direction: column;
    width: 20rem;
  }

  &__card_container {
    grid-row: 3/4;
    grid-column: 1/3;
  }

  &__card_wrapper {
    padding: 2rem 0;
    display: grid;
    grid-column-gap: 3rem;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    max-width: 100%;
  }

  &__card__picture {
    position: relative;
    height: 15rem;
  }

  &__card_details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.4rem;
    grid-column-gap: 2rem;
    padding: 0.5rem 1.5rem;
  }

  &__card_data {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
  }
}

.comment_container {
  grid-row: 4/4;
  grid-column: 1/3;
}

.form_comment {
  margin: 0 auto;
  background-color: $color-white-1;
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 50%;
  height: 50%;

  @media only screen and (max-width: $bp-small) {
    width: 95%;
  }
}

.form__input {
  display: block;
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  padding: 1.25rem 1.75rem;
  border: none;
  width: 100%;
  background-color: $color-grey-1;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &_comment {
    display: block;
    font-family: inherit;
    font-size: 1.5rem;
    color: inherit;
    padding: 1.25rem 1.75rem;
    border: none;
    width: 100%;
    background-color: $color-white-1;
    border-radius: 4px;
    height: 145px;
    margin-bottom: 20px;
  }
}

.commentted_container {
  margin: 0 auto;
  grid-column: 1/3;
  width: 50%;
  padding: 1rem;

  @media only screen and (max-width: $bp-small) {
    width: 95%;
  }
}

.comment_list {
  list-style: none;
}

.annotation_text {
  color: red;
}

.pin_icon {
  &_1 {
    vertical-align: middle;
    color: #2b82cb;
  }

  &_2 {
    vertical-align: middle;
    color: #2fcc3a;
  }
}
