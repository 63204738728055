@import "./../../../../../variables";

.FooterNavItem {
  margin-top: 7rem;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  a {
    color: $color-white;
    text-decoration: none;
    transition: all 0.2s;

    &:hover,
    :active {
      opacity: 0.7;
    }
  }
}
