@import "./../../../../variables";

.FooterNavItems {
  list-style: none;
  display: flex;

  &__copyright {
    justify-self: end;
    color: $color-white;
    margin-top: 3rem;
  }
}
