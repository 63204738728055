$color-white: #f7f7f7;
$color-white-1: #fff;
$color-white-2: #ccc;
$color-grey: #444;
$color-grey-1: #f2f2f2;
$color-grey-2: #bebebe;
$color-grey-3: #5b7083;
$color-blue: #4dabff;
$color-red: #f65161;
$color-yellow: #ffcc00;

$bp-large: 78.15em; // 1250px
$bp-medium: 61.25em; // 980px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
