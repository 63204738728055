@import "./../../../variables";

.react-select-container {
  z-index: 2000;
  &:hover {
    opacity: 0.8;
  }
}

.react-select__control {
  position: relative;
  outline: none;
  width: 17rem;
  border-radius: 1rem !important;
  background-color: $color-blue !important;
  font-size: 1.6rem !important;
  font-weight: 400;
  height: 100%;
  margin-right: 3rem;
  text-align: center;
  z-index: 2000 !important;

  &--is-focused:hover,
  &--is-focused {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  @media only screen and (max-width: $bp-small) {
    margin-right: 0;
    width: 13rem;
  }
}

.react-select__single-value {
  color: $color-white-1 !important;
}

.react-select__placeholder {
  color: $color-white-1 !important;
}

.react-select__indicator {
  color: $color-white-1 !important;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.react-select__menu {
  width: 17rem;
  z-index: 2000 !important;
  position: relative;

  @media only screen and (max-width: $bp-small) {
    width: 13rem;
  }
}
