@import "./../../../../variables";

.form__label {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 00.75rem;
}

.img_wrapper {
  display: flex;
}

.img_boxes {
  display: flex;
}

.add__place_btn {
  font-size: 56px !important;
  margin-right: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  color: $color-blue;
}

.img_box {
  position: relative;
}

.upload_image {
  width: 40px;
  margin-right: 0.5rem;
}

.upload_notion {
  color: $color-blue;
}

.form__upload_image {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.remove_icon_wrapper {
  position: absolute;
  top: -10px;
  right: -3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 1;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  > .remove_icon {
    font-size: 2rem;
  }
}
