@import "./../../variables";

.MenuIcon {
  color: $color-white-1;
  display: table;
}

.icon {
  display: table-cell !important;
  font-size: 3.5rem !important;
  vertical-align: middle;
}

@media (min-width: $bp-smallest) {
  .MenuIcon {
    display: none;
  }
}
