@import "./../../../variables.scss";

.header {
  background-color: $color-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  height: 8rem;
  position: relative;
  z-index: 100;

  @media only screen and (max-width: $bp-smallest) {
    padding: 0 2rem;
  }
}

@media (max-width: $bp-smallest) {
  .desktopOnly {
    display: none;
  }
}
