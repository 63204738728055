@import "./../../../../variables";

.HeaderNavItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;

  @media (min-width: $bp-smallest) {
    flex-flow: row;
  }

  @media (max-width: $bp-smallest) {
    align-items: normal;
  }
}

@media (max-width: $bp-smallest) {
  .desktopOnly {
    display: none;
  }
}

.nav__user_img {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.nav__user_name {
  margin-top: 0.3rem;
}
