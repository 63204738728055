@import "./../../../variables";

.leaflet-container {
  width: 100%;
  height: 100%;
}

.map__popup {
  bottom: 30px !important;
}

.place__name {
  color: $color-grey !important;
  text-decoration: none;

  &:hover {
    border-bottom: 0.1rem solid $color-grey;
    opacity: 0.8;
    cursor: pointer;
  }
}
