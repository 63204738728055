@import "./../../../variables";

.hotel {
  &__card {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
    background-color: $color-white-1;
    transition: 0.3s all;
    display: flex;
    flex-direction: column;
    width: 20rem;
  }

  &__card_container {
    grid-row: 3/4;
    grid-column: 1/3;
  }

  &__card_wrapper {
    padding: 2rem 0;
    display: grid;
    grid-column-gap: 3rem;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    max-width: 100%;
  }

  &__card__picture {
    position: relative;
    height: 15rem;
  }

  &__card-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.4rem;
    grid-column-gap: 2rem;
    padding: 0.5rem 1.5rem;
  }

  &__card_data {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
  }

  &__review {
    margin-right: 0.5rem;
  }
}

.linkbox {
  position: relative;

  & a {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    &:hover {
      opacity: 0.2;
      background-color: $color-grey;
    }
  }
}

.card {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  background-color: $color-white-1;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
  }

  &__picture {
    position: relative;
    height: 30rem;

    &_img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      background-color: $color-grey-1;
    }

    &_top_image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.25rem;
    grid-column-gap: 2rem;
    padding: 2.5rem 3rem;
  }

  &__sub_heading {
    font-size: 1.2rem;
    font-weight: 700;
    grid-column: 1 / -1;
  }

  &__data {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    z-index: 50;

    &_grid {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, 6.5rem);
    }
  }
}

.yellow {
  color: yellow;
}

.grey {
  color: grey;
}
