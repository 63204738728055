@import "./../../variables";

.image-gallery {
  width: 60%;
  height: auto;
  margin: 0px auto;
}

.image-gallery-content,
.image-gallery-slide-wrapper,
.image-gallery-swipe,
.image-gallery-slides {
  width: 100%;
  height: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 270px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 60px;
}

.image-gallery-left-nav {
  left: -80px;
}

.image-gallery-right-nav {
  right: -80px;
}
