@import "./../../variables";

.card {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  background-color: $color-white-1;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
  }

  &__picture {
    position: relative;
    height: 30rem;

    &_img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      background-color: $color-grey-1;
    }

    &_top_image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.25rem;
    grid-column-gap: 2rem;
    padding: 2.5rem 3rem;
  }

  &__sub_heading {
    font-size: 1.2rem;
    font-weight: 700;
    grid-column: 1 / -1;
  }

  &__data {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    z-index: 50;

    &_grid {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, 6.5rem);
    }
  }

  &__icon {
    color: $color-grey-3;
    margin-right: 0.5rem;
    transition: all 0.7s;

    &.heart:hover {
      color: $color-red;
      cursor: pointer;
      filter: drop-shadow(0px 0px 5px $color-red);
      transition: all 0.5s;
    }

    &.bookmark {
      margin-right: 1rem;

      &:hover {
        color: $color-yellow;
        cursor: pointer;
        filter: drop-shadow(0px 0px 5px $color-yellow);
        transition: all 0.5s;
      }
    }
  }
}

.bookmark_filled {
  color: $color-yellow;
}

.heart_filled {
  color: $color-red;
}

.reset_btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  display: inline;
  margin-top: 0.3rem;
}

.linkbox {
  position: relative;

  & .nav_link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    &:hover {
      opacity: 0.2;
      background-color: $color-grey;
    }
  }
}

.mr_10 {
  margin-right: 1rem;
}

.btn_genre {
  font-size: 0.5rem;
  color: $color-grey;
  background-color: $color-white-1;
  border: solid 0.1rem $color-blue;
  border-radius: 100vh;
  padding: 0.5rem;
  text-decoration: none;
  text-align: center;
}
