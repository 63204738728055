@import "./variables";

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* font-size 1em = 10px on default browser settings */

  // @media only screen and (max-width: $bp-medium) {
  //   font-size: 50%;
  // }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: $color-grey-1;
  color: $color-grey;
}

.main-container {
  min-height: 100vh;
}

.alert-style {
  background-color: $color-grey-2;
  color: $color-blue;
  padding: 10px 20px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-family: Arial;
  box-sizing: border-box;
  margin: 10px;
  pointer-events: all;
}

.alert-fail {
  color: red;
}
