@import "./../../../variables";

.form__search {
  display: flex;

  @media only screen and (max-width: $bp-small) {
    display: block;
  }
}

.sort_box {
  display: flex;
  margin: 0 auto 3rem;
  height: 50%;
  cursor: pointer;
  z-index: 9999 !important;
}

.dropdown {
  display: inline-block;
  box-shadow: 0 0 2px $color-white-2;
  transition: all 0.5s ease;
  position: relative;
  text-align: left;
  outline: none;
  width: 17rem;
  border-radius: 1rem;
  background-color: $color-blue;
  color: $color-white-1;
  font-size: 1.6rem !important;
  font-weight: 400;
  height: 100%;
  margin-right: 3rem;
  line-height: 49px;
  text-align: center;

  &_icon {
    margin-left: 3rem;
  }

  &:hover {
    opacity: 0.8;
  }
}

.search_box {
  position: relative;
  margin: 0 auto;
  z-index: 0;
}

.search__icon_btn {
  position: absolute;
  font-size: 2.5rem !important;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.form__input {
  display: block;
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  padding: 1.25rem 1.75rem;
  border: none;
  width: 100%;
  background-color: $color-grey-1;
  border-radius: 1rem;
  margin: 0 auto;
  outline: none;
}
