@import "./../../../variables";

.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 9999;
  background-color: $color-grey;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
  .SideDrawer {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.5s;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(100%);
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
