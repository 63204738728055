@import "./../../variables";

.main-container {
  min-height: 100vh;
}

.form_container {
  background-color: $color-white;
  padding: 3rem;
  width: 100%;
  display: flex;
}

.card_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7rem;
  background-color: $color-white;
  padding: 3rem;
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $bp-small) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.search_num {
  padding: 1rem 3rem;
  background-color: $color-white;
}

.add__place_btn {
  font-size: 56px !important;
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 1000;
  color: $color-blue;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7rem;
  background-color: $color-white;
  padding: 3rem;
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $bp-small) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.pagination-container {
  grid-row: 3/4;
  grid-column: 2/3;
  background-color: $color-white;
}
