@import "./../../variables";

.main {
  background-color: $color-white;
  padding: 8rem 6rem;

  @media only screen and (max-width: $bp-smallest) {
    padding: 8rem 0.5rem;
  }
}

.login_form {
  margin: 0 auto;
  max-width: 55rem;
  background-color: $color-white-1;
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  padding: 5rem 7rem;
  border-radius: 5px;
}

.form__link {
  color: $color-blue;
  text-decoration: none;

  &:hover {
    border-bottom: 0.1rem solid $color-blue;
    opacity: 0.8;
  }
}
