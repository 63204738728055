@import "./../../../variables";

.footer {
  background-color: $color-grey;
  padding: 4rem 4rem 3rem 4rem;
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 0.75rem;
  justify-content: space-between;

  @media only screen and (max-width: $bp-smallest) {
    font-size: 1rem;
    padding: 1rem;
  }
}
