@import "./../../../../../variables";

.nav__el {
  &:link,
  &:visited {
    color: $color-white;
    font-size: 1.6rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s;
    font-weight: 400;
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    margin: 0 1rem;

    // 500px
    @media only screen and (max-width: $bp-smallest) {
      font-size: 1.4rem;
      margin: 2rem 1rem;
    }
  }

  &:hover,
  :active {
    opacity: 0.7;
  }

  &:not(:last-child) {
    margin-right: 3rem;
  }

  &_logo {
    height: 9rem;
  }
}
