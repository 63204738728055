@import './../../../variables';

.react-tabs {
  display: flex;
  color: $color-white-1;
  background: $color-white;
  margin: 3rem;

  @media only screen and (max-width: $bp-small) {
    display: block;
  }
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: $color-white-1;

  @media only screen and (max-width: $bp-small) {
    margin-bottom: 3rem;
  }
}

.react-tabs__tab {
  list-style: none;
  padding: 12px 21px 12px 24px;
  cursor: pointer;
  color: $color-blue;

  &:hover {
    border-left: 4px solid greenyellow;
    opacity: 0.8;
  }

  &--selected {
    background: $color-blue;
    border-color: $color-blue;
    border-left: 4px solid greenyellow;
    color: $color-white-1;
    padding: 12px 21px;
  }

  &--disabled {
    opacity: 0.3;
  }
}

.react-tabs__tab-panel {
  display: none;
  padding: 3rem;
  width: 100%;
  height: 800px;

  @media only screen and (max-width: $bp-small) {
    padding: 0;
  }

  &--selected {
    display: block;
    max-width: 70%;

    @media only screen and (max-width: $bp-small) {
      max-width: 100%;
    }
  }
}

.panel-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.icon_style {
  vertical-align: sub;
  margin-right: 0.5rem;
  font-size: 2rem !important;

  &--category {
    vertical-align: sub;
    margin-left: 1rem;
    font-size: 2rem !important;
  }
}

.list_style {
  font-size: 1.5rem;
}

.content {
  width: 85%;
  height: 50%;
  color: black;
  margin: 0 0 0 auto;

  @media only screen and (max-width: $bp-small) {
    width: 100%;
    margin: 0;
  }
}

.place__card_wrapper {
  padding: 4rem 0;
  display: grid;
  grid-column-gap: 3rem;
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  max-width: 100%;
}

.user_info_heading {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.form_border {
  border-bottom: 1px solid grey;
}

.form_tab {
  @media only screen and (max-width: $bp-small) {
    padding: 1.5rem;
  }
}
