@import "./../../../variables";

.prefectures_window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: $color-white-1;
  border-radius: 9px;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
  z-index: 3000;
  transition: all 0.5s;

  @media only screen and (max-width: $bp-smallest) {
    width: 100%;
    padding: 0.3rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  @media only screen and (min-width: $bp-small) {
    width: 50%;
    padding: 2rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: 2000;
  transition: all 0.5s;
}

.btn__close_modal_prefectures {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 1.6rem;
  font-size: 3.5rem;
  cursor: pointer;
  border: none;
  background: none;
}

.p_modal_content {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 20px 40px;
}

.p_modal_body {
  height: 100%;
}

.p_filterModalItem {
  border-bottom: 1px solid #eee;
  display: flex;
  padding: 15px 0 20px;

  @media only screen and (max-width: $bp-smallest) {
    flex-direction: column;
  }
}

.p_filterModalItem_headline {
  flex-basis: 15%;

  @media only screen and (max-width: $bp-smallest) {
    flex-basis: 25%;
    font-size: 1.8rem;
  }
}

.p_filterModalItem_body {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 20px;
  flex-basis: 85%;

  @media only screen and (max-width: $bp-smallest) {
    justify-content: space-between;
    font-size: 1.6rem;
  }
}

.c_formCheckbox {
  flex-basis: 20%;

  @media only screen and (max-width: $bp-smallest) {
    flex-basis: 40%;
  }
}

.js_checkbox,
.js_checkbox label {
  vertical-align: middle;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
