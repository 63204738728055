@import "./../../../variables";

.FooterLogo {
  grid-row: 1 / 3;
  align-self: center;

  img {
    height: 18rem;

    @media only screen and (max-width: $bp-smallest) {
      height: 10rem;
    }
  }
}
