@import "./../../variables";

.comment__list {
  border-bottom: 0.1rem solid $color-grey;
  padding: 1rem;

  &:first-child {
    border-top: 0.1rem solid $color-grey;
  }
}

.comment-text {
  font-size: 1.2rem;
}
